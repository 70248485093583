<template>
  <v-container
    class="px-10"
    fluid
  >
    <v-row justify="space-between">
      <v-col
        cols="3"
        class="pl-0 pr-2 pt-0"
      >
        <extracoes-geo-perdas-estrutura-banco
          @update-values="estruturaUpdate"
        />
      </v-col>

      <v-col
        cols="9"
        class="px-0 py-0"
      >
        <extracoes-geo-perdas-executar-query
          :active="active"
          :tables="tables"
          :databaseId="databaseId"
          :selectedTable="selectedTable"
          @update-values="queryUpdate"
        />

        <base-card class="mt-1 px-5 py-3">
          <v-tabs
            v-model="tabs"
            fixed-tabs
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              :disabled="!selectedTable"
              href="#tab-query-data"
              class="primary--text"
            >
              <v-icon left> mdi-database </v-icon>
              Dados Query
            </v-tab>
            <v-tab
              href="#tab-history"
              class="primary--text"
            >
              <v-icon left> mdi-view-list </v-icon>
              Extrações Processadas
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabs">
            <v-tab-item value="tab-query-data">
              <first-tab-content
                :headers="headers"
                :queryData="queryData"
                :errorSql="errorSql"
                :loadingExecQuery="loadingExecQuery"
              />
            </v-tab-item>
            <v-tab-item value="tab-history">
              <second-tab-content ref="historyTab" />
            </v-tab-item>
          </v-tabs-items>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ExtracoesGeoPerdasIndex',

  components: {
    ExtracoesGeoPerdasExecutarQuery: () =>
      import(
        '@/components/perdas-tecnicas/extracoes-geo-perdas/ExtracoesGeoPerdasExecutarQuery.vue'
      ),
    ExtracoesGeoPerdasEstruturaBanco: () =>
      import(
        '@/components/perdas-tecnicas/extracoes-geo-perdas/ExtracoesGeoPerdasEstruturaBanco.vue'
      ),
    FirstTabContent: () =>
      import(
        '@/components/perdas-tecnicas/extracoes-geo-perdas/tabs/FirstTabContent.vue'
      ),
    SecondTabContent: () =>
      import(
        '@/components/perdas-tecnicas/extracoes-geo-perdas/tabs/SecondTabContent.vue'
      )
  },

  data: () => ({
    active: [],
    tables: [],
    headers: [],
    queryData: [],
    selectedDatabase: [],
    databaseStructure: [],
    tabs: null,
    errorSql: null,
    databaseId: null,
    selectedTable: null,
    loadingExecQuery: false
  }),

  methods: {
    estruturaUpdate({ selectedTable, databaseId, tables, active }) {
      this.selectedTable = selectedTable;
      this.databaseId = databaseId;
      this.tables = tables;
      this.active = active;
    },
    queryUpdate({ headers, queryData, loading, error, recharge }) {
      this.headers = headers;
      this.queryData = queryData;
      this.loadingExecQuery = loading;
      this.errorSql = error;

      if (recharge && this.$refs.historyTab) {
        this.$refs.historyTab.getAllExtracoes();
      }
    }
  }
};
</script>

<style>
.v-alert__icon {
  align-self: center;
}

.first-v-col-diretorios {
  min-width: 195px !important;
}

@media screen and (max-width: 826px) {
  .break-point-custom-arquivos-dados {
    max-width: 100% !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    margin-left: 0px !important;
  }
}

.dropbox {
  outline: 2px dashed grey;
  outline-offset: -3px;
  padding: 10px 10px;
  min-height: 200px;
  position: relative;
  cursor: pointer;
}

.input-file {
  z-index: 111;
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
</style>
